/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/login/loginActions";
const MIN_30_IN_MILISECONDS = 30 * 60000;

export default function LogoutModal() {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const isUserLogged = useSelector((state) => state.user.loginUser.loggedUser);
  useEffect(() => {
    const intervalId = setInterval(checkSessionInactivity, 10000);
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    const sessionExpired = localStorage.getItem("@session-expired");
    if (sessionExpired && isUserLogged) {
      setModalVisible(true);
    }
  }, [isUserLogged]);

  const checkSessionInactivity = () => {
    const lastActivity = new Date(localStorage.getItem("@session-time"));
    const now = new Date();

    const inactivityTimeInMiliseconds = now - lastActivity;
    if (inactivityTimeInMiliseconds >= MIN_30_IN_MILISECONDS && isUserLogged) {
      setModalVisible(true);
      localStorage.setItem("@session-expired", "true");
    }
  };

  const loggoutUser = () => {
    dispatch(logout());
    setModalVisible(false);
    localStorage.removeItem("@session-expired");
    window.location.reload();
  };

  return (
    <Modal isOpen={modalVisible}>
      <ModalBody>
        <p>Sua sessão expirou, faça login novamente</p>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Button color="primary" onClick={loggoutUser}>
          Fazer login novamente
        </Button>
      </ModalFooter>
    </Modal>
  );
}

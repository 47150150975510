// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import covenant from './covenant';
import howKnowTypes from './howKnowTypes';
import examState from './exams';
import roomState from './rooms';
import procedureState from './procedures';
import uploadState from './upload';
import userPhotoState from './userPhoto';

const helpersReducer = combineReducers({
    covenant,
    howKnowTypes,
    roomState,
    examState,
    procedureState,
    uploadState,
    userPhotoState
});

export default helpersReducer;
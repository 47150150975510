// import external modules
// import internal(own) modules
import { FETCH_PROCEDURES_END } from '../../types/types';

const initialState = {
    procedures: null,
}

const procedureState = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROCEDURES_END:
            return {
            ...state,   
            procedures : action.procedures           
        };   
        
        default:
            return state;
    }
 };

 export default procedureState;
// import external modules
// import internal(own) modules
import { FETCH_PAYMENT_METHODS_END } from '../../types/types';
import { combineReducers } from 'redux';

const initialState = {
    paymentMethods: null,
}

export const paymentMethodState = (state = initialState, action) => {
    switch (action.type) {
         case FETCH_PAYMENT_METHODS_END:
            return {
               ...state, 
               paymentMethods: action.paymentMethods             
            };        

         default:
            return state;
    }
 };

export const paymentMethodReducer = combineReducers({
    paymentMethodState
});
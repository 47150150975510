// import external modules
// import internal(own) modules
import { FETCH_SCHEDULES_END, CREATE_EXAM_END, FETCH_SCHEDULES_RECURRENCE_END } from '../../types/types';
import { combineReducers } from 'redux';

const initialState = {
	schedules: null,
	schedulesRecurrence: null
};

export const agendaState = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_EXAM_END:
			return {
				...state,
			};

		case FETCH_SCHEDULES_END:
			return {
				...state,
				schedules: action.schedules
			};

		case FETCH_SCHEDULES_RECURRENCE_END:
			return {
				...state,
				schedulesRecurrence: action.schedulesRecurrence
			};

		default:
			return state;
	}
};

export const agendaReducer = combineReducers({
	agendaState
});
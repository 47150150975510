import { combineReducers } from "redux";
// import internal(own) modules
import { FETCH_EMPLOYEE_END } from '../../types/types';

const initialState = {
    employees: null,
}

export const employeeState = (state = initialState, action) => {
    switch (action.type) {
         case FETCH_EMPLOYEE_END:
            return {
               ...state,
               employees: action.employees
            };
                 
         default:
            return state;
    }
 };

export const employeeReducer = combineReducers({
    employeeState
});
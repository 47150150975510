// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import { CREATE_ANAMNESE_END, CREATE_COMPANION_ANAMNESE_END } from '../../types/types';

const initialState = {
    anamneseCreated: null,
    anamneseCompanionCreated: null,
}

export const anamneseState = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ANAMNESE_END:
            return {
                ...state,
                anamneseCreated: action.anamnese
            };   
        
        case CREATE_COMPANION_ANAMNESE_END:
            return {
                ...state,
                anamneseCompanionCreated: action.anamnese
            };
        default:
            return state;
    }
 };


export const anamneseReducer = combineReducers({
    anamneseState
});

import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          hasError: false,
          errorMsg : '', 
        };
    }
  
    static getDerivedStateFromError(error) {    // Atualiza o state para que a próxima renderização mostre a UI alternativa.    
        return { hasError: true };  
    }

    componentDidCatch(error, errorInfo) {    // Você também pode registrar o erro em um serviço de relatórios de erro    
        this.setState({errorMsg : errorInfo});
    }

    render() {
        if (this.state.hasError) {      // Você pode renderizar qualquer UI alternativa      
            return <><h1>Algo deu errado.</h1> <br/></>;    
        }
        return this.props.children; 
    }
}

export default ErrorBoundary;

// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import {
  CREATE_PATIENT_END,
  CREATE_COMPANION_END,
  FETCH_PATIENTS_END,
  FETCH_MEDICAL_RECORDS_END,
  FETCH_PATIENT_END,
  FETCH_LOADING,
  RESET_CREATED_STATES,
  CREATE_PATIENT_REQUEST,
  SELECT_PRONTUARIO_TAB_ID,
  RESET_TABS_ID,
  SELECT_ANAMNESE_TAB_ID,
  FETCH_WEIGHT_PATIENT_END,
  CREATE_WEIGHT_PATIENT_END,
  UPDATE_WEIGHT_PATIENT_END,
  FETCH_PRESSURE_PATIENT_END,
  CREATE_PRESSURE_PATIENT_END,
  UPDATE_PRESSURE_PATIENT_END,
  FETCH_DASHBOARD_PATIENT_END,
  FETCH_MEDICAL_CERTIFICATE_END,
  CREATE_MEDICAL_CERTIFICATE_END,
  UPDATE_MEDICAL_CERTIFICATE_END,
  CREATE_COMPLEMENTARY_PATIENT_END,
  UPDATE_COMPLEMENTARY_PATIENT_END,
  FETCH_COMPLEMENTARY_PATIENT_END
} from "../../types/types";

const initialState = {
  patient: null,
  patientComplementary: null,
  patients: null,
  medicalRecords: null,
  companionCreated: null,
  loading: false,
  prontuarioTab: null,
  anamneseTab: null,
};

export const patientState = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_PRONTUARIO_TAB_ID:
      return {
        ...state,
        prontuarioTab: action.prontuarioTab,
      };
    case SELECT_ANAMNESE_TAB_ID:
      return {
        ...state,
        anamneseTab: action.anamneseTab,
      };
    case RESET_TABS_ID:
      return {
        ...state,
        prontuarioTab: "paciente",
        anamneseTab: 0,
      };

    case CREATE_PATIENT_REQUEST:
      return {
        ...state,
        patientImage: null,
      };
    case CREATE_PATIENT_END:
      return {
        ...state,
        patient: action.patient,
        loading: action.loading,
        patientImage: action.patientImage,
      };

    case FETCH_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case CREATE_COMPANION_END:
      return {
        ...state,
        companionCreated: action.companion,
      };

    case FETCH_PATIENTS_END:
      return {
        ...state,
        patients: action.patients,
      };

    case FETCH_MEDICAL_RECORDS_END:
      return {
        ...state,
        medicalRecords: action.medicalRecords,
      };

    case FETCH_PATIENT_END:
      return {
        ...state,
        patient: action.patient,
      };

    case RESET_CREATED_STATES:
      return {
        ...state,
        patient: null,
        companionCreated: null,
        medicalRecords: null,
      };

    case FETCH_WEIGHT_PATIENT_END:
      return {
        ...state,
        patient: action.patientWeight
      };

    case CREATE_WEIGHT_PATIENT_END:
      return {
        ...state,
        patient: action.patientWeight
      };

    case UPDATE_WEIGHT_PATIENT_END:
      return {
        ...state,
        patient: action.patientWeight
      };

    case FETCH_PRESSURE_PATIENT_END:
      return {
        ...state,
        patient: action.patientPressure
      };

    case CREATE_PRESSURE_PATIENT_END:
      return {
        ...state,
        patient: action.patientPressure
      };

    case UPDATE_PRESSURE_PATIENT_END:
      return {
        ...state,
        patient: action.patientPressure
      };

    case FETCH_DASHBOARD_PATIENT_END:
      return {
        ...state,
        patient: action.patientDashboard
      };

    case FETCH_MEDICAL_CERTIFICATE_END:
      return {
        ...state,
        patient: action.patientMedicalCertificate
      };

    case CREATE_MEDICAL_CERTIFICATE_END:
      return {
        ...state,
        patient: action.patientMedicalCertificate
      };

    case UPDATE_MEDICAL_CERTIFICATE_END:
      return {
        ...state,
        patient: action.patientMedicalCertificate
      };

    case FETCH_COMPLEMENTARY_PATIENT_END:
      return {
        ...state,
        patientComplementary: action.complementaryPatient
      };

    case CREATE_COMPLEMENTARY_PATIENT_END:
      return {
        ...state,
        patientComplementary: action.complementaryPatient
      };

    case UPDATE_COMPLEMENTARY_PATIENT_END:
      return {
        ...state,
        patientComplementary: action.complementaryPatient
      };

    default:
      return state;
  }
};

export const patientReducer = combineReducers({
  patientState,
});

import { FETCH_COVENANTS_LIST_END } from '../../types/types';

const initialState = {
    covenantsList : null,
}

const covenant = (state = initialState, action) => {
    switch (action.type) {
       case FETCH_COVENANTS_LIST_END:
         return {
             ...state,
             covenantsList: action.covenantsList
          };

       default:
          return state;
    }
 };
 
 export default covenant;
 
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    const now = new Date();
    localStorage.setItem("@session-time", now);
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('hash1')}`;
    config.headers['key-uuid'] = `${sessionStorage.getItem('hash2')}`;

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;

import {
  FETCH_USER_PROFILE_END,
  FETCH_USER_EMPLOYEE_DATA_END,
  FETCH_USER_ADDRESS_END,
} from "../../types/types";

const initialState = {
  profile: null,
  employeeData: null,
  userAddress: null,
  loading: true
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_END:
      return {
        ...state,
        profile: action.profile,
      };

    case FETCH_USER_EMPLOYEE_DATA_END:
      return {
        ...state,
        employeeData: action.employeeData,
        loading: false
      };

    case FETCH_USER_ADDRESS_END:
      return {
        ...state,
        userAddress: action.userAddress,
        loading: false
      };

    default:
      return state;
  }
};

export default user;

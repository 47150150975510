const initialState = {
    loading : null
};

const patternsCall = /(.*)_(REQUEST|END|ERROR|SUCCESS)/;

const loading = (state = initialState, action) => {
    const { type } = action;

    const matches = patternsCall.exec(type);

    if (!matches) return state;

    const [ , , requestState] = matches;

    return {
        ...state,
        loading : requestState === 'REQUEST',
    };
}
  
export default loading;

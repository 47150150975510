import {
  LOGIN_USER_REQUEST,
  SET_ERROR,
  LOGIN_USER_END,
  FETCH_USER_PROFILE_END,
  FETCH_USER_EMPLOYEE_DATA_END,
  FETCH_USER_ADDRESS_END,
  SET_SUCCESS,
  FETCH_RESET_PASSWORD_REQUEST,
  FETCH_RESET_PASSWORD_END,
} from '../../types/types';
import api from '../../../services/api';
import { getErrorMessage } from '../../../utility/functions';
import { text } from '../../../services/locale';
import { auth, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from '../../../config/firebaseConfig';

export const login = ({ UserName, Password }) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_USER_REQUEST,
    });

    //função que vai fazer o login no firebase
    signInWithEmailAndPassword(auth, UserName, Password)
      .then((userAuth) => {
        const { accessToken, uid } = userAuth.user;

        sessionStorage.setItem('hash1', accessToken);
        sessionStorage.setItem('hash2', uid);

        api
          .get('/users-extends/detail')
          .then((response) => response.data)
          .then((loggedUser) => {
            try {
              if (loggedUser) {
                //evita que o a quantidade de informacao exceda a capacidade
                //de armazenamento do storage
                loggedUser.photo = '';
                sessionStorage.setItem('user', JSON.stringify(loggedUser));

                dispatch({
                  type: LOGIN_USER_END,
                  loggedUser: loggedUser,
                });
              } else {
              }
            } catch (err) {
              dispatch({
                type: SET_ERROR,
                error: text('login.browser_cota'),
              });
            }
          })
          .catch((reason) => {
            let message = getErrorMessage(reason);
            dispatch({
              type: SET_ERROR,
              error: message,
            });
          });
      })
      .catch(() => {
        dispatch({
          type: SET_ERROR,
          error: text('login.login_invalido'),
        });
      });
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_USER_REQUEST,
    });

    sessionStorage.removeItem('hash1');
    sessionStorage.removeItem('hash2');
    sessionStorage.removeItem('user');

    //função que vai desconectar o usario do firebase
    signOut(auth)
      .then(() => dispatch({
        type: LOGIN_USER_END,
        loggedUser: null,
      }));

    dispatch({
      type: FETCH_USER_PROFILE_END,
      profile: null,
    });

    dispatch({
      type: FETCH_USER_EMPLOYEE_DATA_END,
      employeeData: null,
    });

    dispatch({
      type: FETCH_USER_ADDRESS_END,
      userAddress: null,
    });
  };
};

export const resetPassword = (email) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_RESET_PASSWORD_REQUEST,
    });

    //função que vai enviar email com o link para resetar a senha
    sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch({
          type: FETCH_RESET_PASSWORD_END,
          loggedUser: email,
        });

        dispatch({
          type: SET_SUCCESS,
          success: text('login.send_reset_password')
        });
      });

  };
};

// import external modules
// import internal(own) modules
import { FETCH_PHOTOS_END } from "../../types/types";

const initialState = {
  userPhoto: null,
};

const userPhotoState = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PHOTOS_END:
      return {
        ...state,
        userPhoto: action.userPhoto,
      };

    default:
      return state;
  }
};

export default userPhotoState;

// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import loginUser from './loginUser';
import user from './user';

const userReducer = combineReducers({
    loginUser,
    user,
});

export default userReducer;
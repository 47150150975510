import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD7ZgeUyeDDj4YM3dAr8uJd81RuC2OKR-A",
  authDomain: "clinic-management-344401.firebaseapp.com",
  projectId: "clinic-management-344401",
  storageBucket: "clinic-management-344401.appspot.com",
  messagingSenderId: "300320738424",
  appId: "1:300320738424:web:17e8443b4a80267171318d",
  measurementId: "G-KYQJDM81P2"
};

initializeApp(firebaseConfig);
const auth = getAuth();

export {
  auth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
};
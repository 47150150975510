import { SET_ERROR, SET_SUCCESS } from '../../types/types';

const initialState = {
    error : null,
    success: null,
}

const main = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR:
          return {
             ...state,
             error : action.error
          };
        
        case SET_SUCCESS:
            return {
                ...state,
                success : action.success
            };

        default:
          return state;
    }
 };
 
 export default main;
import { FETCH_HOW_KNOW_TYPES_END } from '../../types/types';

const initialState = {
    howKnowTypesList : null,
}

const howKnowTypes = (state = initialState, action) => {
    switch (action.type) {
       case FETCH_HOW_KNOW_TYPES_END:
         return {
             ...state,
             howKnowTypesList: action.howKnowTypesList
          };

       default:
          return state;
    }
 };
 
 export default howKnowTypes;
 
import { combineReducers } from "redux";

import {
  FETCH_DASHBOARD_HOME_END,
} from "../../types/types";

const initialState = {
  patient: null,
};

export const homeState = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_HOME_END:
      return {
        ...state,
        home: action.homeDashboard,
      };

    default:
      return state;
  }

};

export const homeReducer = combineReducers({
  homeState,
});
// import external modules
import { combineReducers } from "redux";
import customizer from "./customizer/";
import userReducer from './user';
import addressReducer from "./address";
import { patientReducer } from "./patient/patient";
import { homeReducer } from "./home/home";
import { anamneseReducer } from "./anamnese";
import { agendaReducer } from "./agenda";
import { paymentMethodReducer } from "./paymentMethod";
import { employeeReducer } from "./employee";
import { treatmentReducer } from "./treatments";
import helpersReducer from "./helpers";
import loading from "./loading";
import main from './main';

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
   toastr: toastrReducer, // <- Mounted at toastr.
   customizer: customizer,
   user: userReducer,
   address: addressReducer,
   patient: patientReducer,
   home: homeReducer,
   anamnese: anamneseReducer,
   agenda: agendaReducer,
   paymentMethod: paymentMethodReducer,
   helper: helpersReducer,
   employee: employeeReducer,
   treatment: treatmentReducer,
   loading: loading,
   main: main,
});

export default rootReducer;

// import external modules
// import internal(own) modules
import { FETCH_EXAMS_END } from '../../types/types';

const initialState = {
    exams: null,
}

const examState = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EXAMS_END:
            return {
            ...state,   
            exams : action.exams           
        };   
        
        default:
            return state;
    }
 };

 export default examState;
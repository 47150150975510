// import external modules
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { getLocale } from "./services/locale";

// import internal(own) modules
import registerServiceWorker from "./registerServiceWorker";
import { store } from "./redux/storeConfig/store";
import ReduxToastr from "react-redux-toastr";

import "font-awesome/css/font-awesome.min.css";

import "./index.scss";
import Spinner from "./components/spinner/spinner";
import ErrorBoundary from "./components/errorBoundary";
import LogoutModal from "./components/modals/logoffModal";

const currentLanguage = getLocale();
if (currentLanguage === "pt-BR") {
  require("moment/locale/pt-br");
}

const LazyApp = lazy(() => import("./app/app"));

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <LazyApp />
        <LogoutModal />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </Suspense>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
registerServiceWorker();

import axios from "axios";
import { text } from "../services/locale";

export const formatPrecoValue = (value) => {
  value = value.toString();

  value = value.replace(/\./g, "");
  value = value.replace(",", ".");
  return value;
};

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const searchZipCode = async (zipCode) => {
  if (zipCode && zipCode.length === 9) {
    zipCode = zipCode.replace(/-/g, "");
    const api = axios.create();
    return api.get(`https://api-fire.natan.app/enderecos?cep=${zipCode}`);
  }
  return {};
};

export const fillAddress = (zipCode, setFieldValue) => {
  searchZipCode(zipCode)
    .then((response) => response.data)
    .then((address) => {
      if (address) {
        setFieldValue("street", address.logradouro);
        setFieldValue("city", address.cidade);
        setFieldValue("state", address.estado);
        setFieldValue("neighborhood", address.bairro);
      }
    });
};

export const getErrorMessage = (reason) => {
  let message = "";
  if (reason.response && reason.response.data.i38n) {
    message = text(reason.response.data.i38n);
  } else if (reason.response && reason.response.data.message) {
    message = reason.response.data.message;
  } else {
    message = text("geral.erro_generico");
  }

  return message;
};

export const truncateCode = (code, sizeToShow) => {
  var length = code.length > 8 ? 8 : code.length - 1;

  if (code) {
    if (sizeToShow && sizeToShow <= code.length) {
      length = sizeToShow;
    }

    return code.substring(0, length);
  }

  return code;
};

export const trimSpacesFromDecimal = (decimal) => {
  if (decimal) {
    let decimalArray = decimal.split(".");

    decimalArray[0] = decimalArray[0].trim();
    decimalArray[1] = decimalArray[1].trim();

    decimalArray = decimalArray.join(".");

    return decimalArray;
  } else {
    return decimal;
  }
};

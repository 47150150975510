import { CREATE_ADDRESS_END } from '../../types/types';

const initialState = {
    address : null,
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ADDRESS_END:
            return {
                ...state,
                address: action.address
            };
    
        default:
            return state;
    }
 };
 
 export default user;
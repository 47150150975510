// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import { FETCH_TREATMENT_END } from '../../types/types';

const initialState = {
    treatments: null,    
}

export const treatmentState = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TREATMENT_END:
            return {
                ...state,
                treatments: action.treatments
            };
        default:
            return state;
    }
 };

export const treatmentReducer = combineReducers({
   treatmentState
});

// import external modules
// import internal(own) modules
import { UPLOAD_PHOTO_END } from '../../types/types';

const initialState = {
}

const updaloadState = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_PHOTO_END:
            return {
            ...state,         
        };   
        
        default:
            return state;
    }
 };

 export default updaloadState;